import React, { useState, useEffect } from 'react';
import './ProgressBar.css'; // Import the CSS file
const ProgressBar = ({d}) => {
  const [progress, setProgress] = useState(0); // Initialize progress to 0%
  const duration = d * 1000; // 10 seconds in milliseconds
  const updateInterval = 100; // Update every 100ms
  const totalUpdates = duration / updateInterval; // Total number of updates
  const increment = 100 / totalUpdates; // Percentage increment per update

  useEffect(() => {
    // Start the interval timer
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + increment;
        if (newProgress >= 100) {
          clearInterval(interval); // Clear interval when progress reaches 100%
          return 100;
        }
        return newProgress;
      });
    }, updateInterval);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [increment, updateInterval]);

  return (
    <div className="progress-container" style={{ width: '300px', margin: '20px auto', textAlign: 'center' }}>
      <progress value={progress} max="100" style={{ width: '100%', height: '10px',color:'yellow' }}></progress>
    </div>
  );
};

export default ProgressBar;