import React from 'react';

function LeaderBoardComponent() {
  const users = [
    { rank: 1, avatar: '/user1.png', handle: '@user1' },
    // Add more users
  ];

  return (
    <div className="leaderboard">
      {users.map((user) => (
        <div key={user.rank} className="user">
          <span>{user.rank}.</span>
          <img src={user.avatar} alt={user.handle} />
          <span>{user.handle}</span>
        </div>
      ))}
    </div>
  );
}

export default LeaderBoardComponent;