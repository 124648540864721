// BottomSection.jsx

import React, { useState, useEffect } from 'react';
import './BottomSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import database from '../firebase';
import {  ref, onValue, off } from 'firebase/database';


// Define the PlayerType interface (for TypeScript users)
// interface PlayerType {
//   userid: string;
//   name: string;
//   status: string;
//   optionIndexSelected: number | null;
//   time: number;
// }

function BottomSection({ mode, contest }) {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const roomId = "_main"; // Change as needed
    const playersRef = ref(database, `rooms/${roomId}/players`);

    const handleData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const playersList = Object.values(data);
        console.log(data);
        setPlayers(playersList);
      } else {
        setPlayers([]);
      }
    };

    onValue(playersRef, handleData, (error) => {
      console.error("Error fetching players:", error);
    });

    // Cleanup listener on unmount
    return () => {
      off(playersRef, 'value', handleData);
    };
  }, []);

  return (
    <div className="bottom-section">
      <div className="center">
        <div className="avatar-container">
          {players.map((player) => {
            const hasAnswered = player.optionIndexSelected !== null && player.optionIndexSelected !== undefined;
            const isCorrect = hasAnswered && player.optionIndexSelected === player.answerIndex;
            const initials = player.name.slice(0, 2).toUpperCase();
console.log(isCorrect)
            return (
              <div key={player.userid} className="avatar-wrapper">
                {isCorrect && (mode === 3 || mode === 4) ? (
                  <div className="correct-avatar">
                    <span className="avatar-initials">{initials}</span>
                  </div>
                ) : hasAnswered ? (
                  <div className="answered-avatar">
                    <span className="avatar-initials">{initials}</span>
                  </div>
                ) : (
                  <div className="unanswered-avatar">
                    <span className="avatar-initials">{initials}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BottomSection;