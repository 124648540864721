import React from 'react';
import './SwybleIntroComponent.css'; 
function SwybleIntroComponent({ question }) {
  return (
    <div className="swyble-intro center1">
        <h1>{question.category}</h1>
        <h2>{question.tags.join(', ')}</h2>
    </div>
  );
}

export default SwybleIntroComponent;