// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyC1DHMLL4Rd9Ur1wGmYiW0w5t70yIpmcFE",
  authDomain: "onehousehelen.firebaseapp.com",
  databaseURL: "https://onehousehelen-default-rtdb.firebaseio.com",
  projectId: "onehousehelen",
  storageBucket: "onehousehelen.appspot.com",
  messagingSenderId: "647968972366",
  appId: "1:647968972366:web:ccd459dfc0fa31571ead1f",
  measurementId: "G-8M9HZQZK4E"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;